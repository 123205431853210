<template>
  <div>
    <!--    <home-header></home-header>-->
    <router-view v-if="amReady" :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import homeHeader from './components/Header'
// import {loadScript} from 'devegram-javascript-util'
export default {
  data() {
    return {
      amReady: false
    }
  },
  components: {
    homeHeader
  },
  mounted() {


    $store.dispatch('App-User-api/verify', {
      onError: (error, resolve, reject) => {reject()}
    }).then((r) => {
      $router.replace('/portal/dashboard')
    }).catch(error => {
      this.amReady = true
    })

    /*
      let basePath  = '/portals/home/assets/js/'
      loadScript([
          {
              src: basePath + 'jquery.min.js',
              onload: function () {
                alert('loading secriupts')
                  loadScript([
                      {src: basePath + 'jquery.scrolly.min.js'},
                      {src: basePath + 'query.scrollex.min.js'},
                      {src: basePath + 'browser.min.js'},
                      {src: basePath + 'breakpoints.min.js'},
                      {src: basePath + 'util.js'},
                      {src: basePath + '/theme-js/mixitup.min.js'},
                      {src: basePath + 'main.js'},
                  ])
              }
          }
      ])*/

    if (this.$route.name == 'portal-home-profile' || this.$route.name == 'portal-home-login') {
      let element = document.createElement("link");
      element.setAttribute("rel", "stylesheet");
      element.setAttribute("type", "text/css");
      element.setAttribute("portal", "portal-home");
      element.setAttribute("href", "/portals/home/assets/css/main.css");
      document.getElementsByTagName("head")[0].appendChild(element);
    }
  }
}
</script>
<!--

<style scoped>
  @import "/portals/home/assets/css/main.css";
</style>
-->
